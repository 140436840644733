





































import {Component, Prop, Vue} from 'vue-property-decorator';
import {get, edit} from '@/api/identity/permission';

@Component({})
export default class PermissionIndex extends Vue {
  @Prop({required: true, default: () => false}) private visible!: boolean;
  @Prop({}) private providerName!: string;
  @Prop({}) private providerKey!: string;
  private groupData: any = [];
  private treeData: any = [];
  private defaultProps = {
    label: 'label',
    value: 'value'
  };
  private currentProvider = '';
  private defaultCheckedKeys = [];
  private permissions: any = [];
  private strictly = false;

  public closeDialog() {
    this.$emit('closeDialog', false);
  }

  public getTree(item: any) {
    this.currentProvider = item.displayName;
    this.defaultCheckedKeys = [];
    this.strictly = true;
    this.toTreeData(item.permissions, 'parentName', 'name', 'displayName');

    this.$nextTick(() => {
      for (const i of item.permissions) {
        if (i.isGranted) {
          (this.$refs.tree as any).setChecked(i.name, true, false);
        }
      }
    });
  }


  public toTreeData(data: any, pid: any, name: any, displayName: any) {
    const parent = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i][pid]) {
        const obj = {
          label: data[i][displayName],
          value: data[i][name],
          children: []
        };
        parent.push(obj);
      }
    }
    this.children(parent, data, pid, name, displayName);
  }


  public children(parent: any, data: any, pid: any, name: any, displayName: any) {// 给当前组织的直系子节点添加剩下的子节点
    const _this = this;
    if (data) {
      for (let i = 0; i < parent.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (parent[i].value === data[j][pid]) {
            const obj = {
              label: data[j][displayName],
              value: data[j][name],
              children: [],
              parentName: parent[i].value
            };
            parent[i].children.push(obj);
          }

        }
        _this.children(parent[i].children, data, pid, name, displayName);
      }
    }
    _this.treeData = parent;
  }


  public saveProvider() {
    edit(this.providerName, this.providerKey, this.permissions).then(() => {
      this.$notify({
        message: '保存成功',
        type: 'success',
        title: ''
      });

      this.closeDialog();
    });
  }


  public checkChange(data: any, flag: any) {
    this.strictly = true;
    this.$nextTick(() => {
      if (!flag) {
        if (data.children) {
          // 循环子集将他们的选中取消
          data.children.map((item: any) => {
            (this.$refs.tree as any).setChecked(item.value, false, false);
            this.removeIndex(item);
          });
          this.removeIndex(data);
        }
      } else {
        if (data.parentName) {
          // 如果不为空则将其选中
          (this.$refs.tree as any).setChecked(data.parentName, true, false);
        }
      }
    });

  }


  public check() {
    // TODO 删除选中权限节点
    for (const node of (this.$refs.tree as any).getCheckedKeys()) {
      // TODO 判断是否存在
      const index = this.getIndex(node);
      if (index < 0) {
        this.permissions.push({name: node, isGranted: true});
      }
    }
  }


  public removeIndex(row: any) {
    const index = this.getIndex(row.value);
    if (index >= 0) {
      this.$set(this.permissions[index], 'isGranted', false);
    }
  }


  public getIndex(val: any) {
    const index = this.permissions.findIndex((item: any) => {
      if (item.name === val) {
        return true;
      }
    });

    return index;
  }


  public getCount(item: any) {
    const temp = item.permissions.filter(function(m: any) {
      return m.isGranted;
    });

    return temp.length;
  }

  private mounted() {
    this.getProvider();
  }

  private getProvider() {
    get(this.providerName, this.providerKey).then((res) => {
      if (res) {
        // this.groupData = res.groups
        if (res.groups.length > 0) {
          for (const i of res.groups) {
            // TODO 关闭特性管理 和 设置管理
            if (i.name === 'FeatureManagement' || i.name === 'SettingManagement') {
              continue;
            }

            // TODO 加载初始权限
            for (const j of i.permissions) {
              if (j.isGranted) {
                this.permissions.push({name: j.name, isGranted: j.isGranted});
              }
            }

            this.groupData.push(i);
          }

          this.getTree(this.groupData[0]);
        }
      }
    });
  }

}
