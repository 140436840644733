import {ExtensibleEntityDto} from './ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface UserDto extends ExtensibleEntityDto<Guid> {
  userName: string;
  password: string;
  name: string;
  email: string;
  phoneNumber: string;
  isActive: boolean
}

export class CreateOrUpdateUserDto {
  public id: Guid = Guid.createEmpty();
  public userName: string = '';
  public password: string = '';
  public name: string = '';
  public email: string = '';
  public phoneNumber: string = '';
  public roleNames: string[] = [];
  public isActive: boolean = true;
}

export class GetListUserDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
}
