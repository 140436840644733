



















































































import {Component, Vue} from 'vue-property-decorator';
import PermissionIndex from '@/components/permissions/Index.vue';
import {UserDto, CreateOrUpdateUserDto, GetListUserDto} from '@/framework/models/User';
import {ListResultDto, PageResultDto} from '@/framework/models/ResultDto';
import UserApi from '@/api/identity/user';

@Component({
  components: {
    PermissionIndex
  }
})
export default class User extends Vue {
  private api = new UserApi();
  private listQuery = new GetListUserDto();
  private result: PageResultDto<UserDto> = {
    items: new Array<UserDto>(),
    totalCount: 0
  };

  private dialog: any = {
    title: '',
    visible: false,
    permission: {
      visible: false
    }
  };
  private provider: any = {
    name: 'U',
    key: ''
  };

  private formData = new CreateOrUpdateUserDto();

  private passwordRules = {
    required: true, message: '请输入密码', trigger: 'blur'
  };

  private rules = {
    userName: [
      {required: true, message: '请输入用户名', trigger: 'blur'}
    ],
    password: [
      this.passwordRules
    ],
    name: [
      {required: true, message: '请输入姓名', trigger: 'blur'}
    ],
    email: [
      {required: true, message: '请输入邮箱地址', trigger: 'blur'}
    ]
  };

  public async mounted() {
    await this.list();
  }

  public async list() {
    this.result = await this.api.getListAsync(this.listQuery);
  }

  public async updateHandle(row: any) {
    this.resetForm();
    // delete this.rules.password
    this.dialog.title = '编辑用户';
    this.dialog.visible = true;
    this.formData = Object.assign({roleNames: []}, row);

    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  public handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      await this.api.DeleteAsync(row.id);
      await this.list();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  public closeDialog(v: boolean) {
    this.dialog.permission.visible = v;
  }

  public permissionHandle(row: any) {
    this.provider.key = row.id;
    this.dialog.permission.visible = true;
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增用户';
    this.dialog.visible = true;
    this.rules.password = [this.passwordRules];
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.formData = new CreateOrUpdateUserDto();
  }

  private creating() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (valid) {
        await this.api.CreateAsync(this.formData);
        await this.list();
        this.$notify({
          message: '添加成功',
          type: 'success',
          title: ''
        });
        this.dialog.visible = false;
      }
    });
  }

  private edited() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (valid) {
        await this.api.UpdateAsync(this.formData.id, this.formData);
        await this.list();
        this.$notify({
          message: '编辑成功',
          type: 'success',
          title: ''
        });
        this.dialog.visible = false;
      }
    });
  }
};
