import request from '@/utils/request';

const url = `/permission-management/permissions`;

export function get(providerName: string, providerKey: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url,
      method: 'get',
      params: {
        providerName,
        providerKey
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  });
}

export function edit(providerName: string, providerKey: string, permissions: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}?providerName=${providerName}&providerKey=${providerKey}`,
      method: 'put',
      data: {
        permissions
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  });
}
