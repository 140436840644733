import CrudAppService from '@/framework/CrudAppService';
import {UserDto, CreateOrUpdateUserDto, GetListUserDto} from '@/framework/models/user';
import {Guid} from 'guid-typescript';


export default class UserApi extends CrudAppService<UserDto, UserDto, Guid, GetListUserDto, CreateOrUpdateUserDto, CreateOrUpdateUserDto> {
  constructor() {
    super(`/identity/users`);
  }
}
